<template>
  <a-dims-search-bar hide-search>
    <div class="flex justify-between p-4 space-x-2 -mx-4">
      <button @click="$router.back" class="space-x-2 text-gray-700 uppercase flex items-center">
        <i class="icon-arrowLeft text-gray-600" />
        <span class="font-bold">Atrás</span>
      </button>
    </div>
  </a-dims-search-bar>

  <div class="mt-16 text-left text-black">
    <div class="mx-4">
      <h2 class="text-black text-xl font-bold mb-2">Suscripción</h2>

      <div class="border rounded-lg p-2 shadow-md">
        <div class="flex justify-between my-2">
          <h2 class="font-bold text-lg ">{{ currentPlan?.price_name }}</h2>
          <h2>{{ currentPlan?.price_string }}</h2>
        </div>
        <h2 class="text-sm">siguiente factura</h2>
        <h2>factura date</h2>
<!--        <a href="#" class="text-primary">Cambiar plan</a>-->
<!--        <router-link :to="{ name: 'subscription.plan.change', params: { userId: user.id }}" class="text-primary">Cambiar plan</router-link>-->
        <div @click="billingPortal()" class="text-primary">Cambiar plan</div>
      </div>

      <div class="mt-4">
        <div class="flex justify-between items-end">
          <p class="text-xl font-bold">Mis datos de facturación</p>
          <router-link :to="{ name: 'subscription.billing.edit', params: { userId: user.id }}" class="text-primary">Editar</router-link>
        </div>
        <div class="flex flex-col">
          <div class="my-1">
            <p class="text-sm text-gray-400">NOMBRE</p>
            <p>{{ user?.restaurant?.name }}</p>
          </div>

          <div class="my-1">
            <p class="text-sm text-gray-400">CIF</p>
            <p>{{ user?.restaurant?.cif }}</p>
          </div>
          <div class="my-1">
            <p class="text-sm text-gray-400">DIRECCIÓN</p>
            <p>{{ user?.restaurant?.address }}</p>
          </div>
          <div class="my-1">
            <p class="text-sm text-gray-400">TELÉFONO</p>
            <p>{{ user?.restaurant?.phone }}</p>
          </div>
          <div class="my-1">
            <p class="text-sm text-gray-400">CORREO ELECTRÓNICO</p>
            <p>{{ user?.restaurant?.email }}</p>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="flex justify-between items-end mb-4">
          <p class="text-xl font-bold">Mis facturas</p>
          <p class="text-primary">Ver todas</p>
        </div>
        <div v-for="value in 4" :key="value">
          <div class="flex justify-between border-b-2 pb-1 mt-2 items-center">
            <h2 class="text-lg">{{ 'Name: ' + value }}</h2>
            <a class="icon-bookmark text-xl text-gray-400"></a>
          </div>
        </div>
      </div>

      <div class="mt-4 text-center text-SFRed">
        <a @click="cancelSubscription()">Darme de baja</a>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRepository, useSession } from '../../../hooks'

export default {
  name: 'Subscription',
  setup (props) {
    const user = ref([])
    const currentPlan = ref([])
    const userRepository = useRepository(({ users }) => users)
    const stripeRepository = useRepository(({ stripe }) => stripe)
    // const restaurantsRepository = useRepository(({ restaurants }) => restaurants)
    const userSession = useSession()

    const cancelSubscription = () => {
      stripeRepository.cancel()
        .then((res) => {
          console.log(res)
        })
    }

    const billingPortal = () => {
      stripeRepository.stripeBillingPortal()
        .then((res) => {
          console.log(res)
        })
    }

    onMounted(() => {
      console.log(props.profile)
      const query = {
        with: 'restaurant.billingAddress'
      }
      userRepository.show(userSession.user.id, query)
        .then(({ data: response }) => {
          user.value = response
          currentPlan.value = response.current_plan
        })

      // restaurantsRepository.show(userSession.user.restaurant.id)
      //   .then(({ data: response }) => {
      //     console.log(response)
      //   })
    })

    return {
      user,
      currentPlan,
      cancelSubscription,
      billingPortal
    }
  }
}
</script>

<style scoped>

</style>
